"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _admin = _interopRequireDefault(require("./admin"));

//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  components: {
    adminDashboard: _admin.default
  },
  data: function data() {
    return {
      currentRole: 'adminDashboard'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['roles'])),
  created: function created() {}
};
exports.default = _default;