"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.logintoken = logintoken;
exports.logintokenfast = logintokenfast;
exports.getInfo = getInfo;
exports.getCodeImg = getCodeImg;

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

function login(username, password, validCode, deviceId) {
  return (0, _request.default)({
    url: 'oauth/admin/login',
    method: 'post',
    data: _qs.default.stringify({
      username: username,
      password: password,
      validCode: validCode,
      deviceId: deviceId
    })
  });
}

function logintoken(username, password, validCode, deviceId, grant_type, scope, client_id, client_secret) {
  return (0, _request.default)({
    url: 'api-auth/oauth/token',
    method: 'post',
    data: _qs.default.stringify({
      username: username,
      password: password,
      validCode: validCode,
      deviceId: deviceId,
      grant_type: grant_type,
      scope: scope,
      client_id: client_id,
      client_secret: client_secret
    })
  });
}

function logintokenfast(username, password, validCode, deviceId, grant_type, scope, client_id, client_secret) {
  return (0, _request.default)({
    url: 'oauth/token',
    method: 'post',
    data: _qs.default.stringify({
      username: username,
      password: password,
      validCode: validCode,
      deviceId: deviceId,
      grant_type: grant_type,
      scope: scope,
      client_id: client_id,
      client_secret: client_secret
    })
  });
}

function getInfo() {
  return (0, _request.default)({
    url: 'oauth/userinfo',
    method: 'post'
  });
}

function getCodeImg() {
  return (0, _request.default)({
    url: 'auth/vCode',
    method: 'get'
  });
}