"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _config = _interopRequireDefault(require("@/config"));

var TokenKey = _config.default.TokenKey;

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(TokenKey, token, {
      expires: _config.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}