var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.data, size: "small" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "remoteaddr", label: "ip地址" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "登录时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(_vm.flags, function(item) {
                    return _c(
                      "div",
                      { key: item.value },
                      [
                        scope.row.flag.toString() === item.value
                          ? _c("templte", [_vm._v(_vm._s(item.label))])
                          : _vm._e()
                      ],
                      1
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": true,
              prop: "remark",
              label: "异常信息"
            }
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "margin-top": "8px" },
        attrs: {
          total: _vm.total,
          "current-page": _vm.page,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: { "size-change": _vm.sizeChange, "current-change": _vm.pageChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }