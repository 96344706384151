"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.get = get;
exports.getObj = getObj;
exports.add = add;
exports.del = del;
exports.edit = edit;

var _request = _interopRequireDefault(require("@/utils/request"));

function get(dictName) {
  var params = {
    dictName: dictName
  };
  return (0, _request.default)({
    url: 'system/dictDetail',
    method: 'get',
    params: params
  });
}

function getObj(dictNames) {
  var params = {
    dictNames: dictNames
  };
  return (0, _request.default)({
    url: 'system/dictDetail/arr',
    method: 'get',
    params: params
  });
}

function add(data) {
  return (0, _request.default)({
    url: 'system/dictDetail',
    method: 'post',
    data: data
  });
}

function del(id) {
  return (0, _request.default)({
    url: 'system/dictDetail/' + id,
    method: 'delete'
  });
}

function edit(data) {
  return (0, _request.default)({
    url: 'system/dictDetail',
    method: 'put',
    data: data
  });
}