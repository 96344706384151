"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {};
  },
  computed: {
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch('changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    },
    settingBtn: {
      get: function get() {
        return this.$store.state.settings.settingBtn;
      },
      set: function set(val) {
        this.$store.dispatch('changeSetting', {
          key: 'settingBtn',
          value: val
        });
      }
    },
    uniqueOpened: {
      get: function get() {
        return this.$store.state.settings.uniqueOpened;
      },
      set: function set(val) {
        this.$store.dispatch('changeSetting', {
          key: 'uniqueOpened',
          value: val
        });
      }
    }
  }
};
exports.default = _default;