var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "label-position": "left",
            "label-width": "0px"
          }
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("龙达光学管理系统")]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "text",
                    "auto-complete": "off",
                    placeholder: "账号"
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username"
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon",
                    staticStyle: {
                      height: "39px",
                      width: "13px",
                      "margin-left": "2px",
                      "line-height": "39px",
                      "margin-top": "8px"
                    },
                    attrs: { slot: "prefix", "icon-class": "user" },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    "auto-complete": "off",
                    placeholder: "密码"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    }
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password"
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon",
                    staticStyle: {
                      height: "39px",
                      width: "13px",
                      "margin-left": "2px",
                      "margin-top": "8px"
                    },
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "code" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: { "auto-complete": "off", placeholder: "验证码" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  }
                },
                model: {
                  value: _vm.loginForm.code,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "code", $$v)
                  },
                  expression: "loginForm.code"
                }
              }),
              _c("div", { staticClass: "login-code" }, [
                _c("img", {
                  attrs: { src: _vm.codeUrl },
                  on: { click: _vm.getCode }
                })
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%", "line-height": "56px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary"
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    }
                  }
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }