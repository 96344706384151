"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initData = initData;

var _request = _interopRequireDefault(require("@/utils/request"));

function initData(url, params) {
  return (0, _request.default)({
    url: url,
    method: 'get',
    params: params
  });
}