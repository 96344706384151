"use strict";

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  beforeCreate: function beforeCreate() {
    var _this$$route = this.$route,
        params = _this$$route.params,
        query = _this$$route.query;
    var path = params.path;
    this.$router.replace({
      path: '/' + path,
      query: query
    });
  },
  render: function render(h) {
    return h(); // avoid warning message
  }
};
exports.default = _default;