"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _config = _interopRequireDefault(require("@/config"));

var _elementVariables = _interopRequireDefault(require("@/styles/element-variables.scss"));

var settings = {
  state: {
    showRightPanel: false,
    tagsView: _config.default.tagsView,
    fixedHeader: _config.default.fixedHeader,
    sidebarLogo: _config.default.sidebarLogo,
    theme: _elementVariables.default.theme,
    settingBtn: _config.default.settingBtn,
    uniqueOpened: _config.default.uniqueOpened
  },
  mutations: {
    CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
      var key = _ref.key,
          value = _ref.value;

      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = value;
      }
    }
  },
  actions: {
    changeSetting: function changeSetting(_ref2, data) {
      var commit = _ref2.commit;
      commit('CHANGE_SETTING', data);
    }
  }
};
var _default = settings;
exports.default = _default;