"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.del = del;
exports.edit = edit;
exports.validPass = validPass;
exports.updatePass = updatePass;
exports.updateEmail = updateEmail;
exports.updateAvtar = updateAvtar;
exports.enabled = enabled;
exports.exportExcel = exportExcel;
exports.isbind = isbind;
exports.unbind = unbind;
exports.getewm = getewm;
exports.deldata = deldata;

var _request = _interopRequireDefault(require("@/utils/request"));

function add(data) {
  return (0, _request.default)({
    url: 'system/users',
    method: 'post',
    data: data
  });
}

function del(id) {
  return (0, _request.default)({
    url: 'system/users/' + id,
    method: 'delete'
  });
}

function edit(data) {
  return (0, _request.default)({
    url: 'system/users',
    method: 'put',
    data: data
  });
}

function validPass(password) {
  var data = {
    password: password
  };
  return (0, _request.default)({
    url: 'system/users/validPass',
    method: 'POST',
    data: data
  });
}

function updatePass(data) {
  // const data = {
  //   password
  // }
  return (0, _request.default)({
    url: 'system/users/updatePass/',
    method: 'put',
    data: data
  });
}

function updateEmail(code, data) {
  return (0, _request.default)({
    url: 'system/users/updateEmail/' + code,
    method: 'post',
    data: data
  });
}

function updateAvtar(data) {
  return (0, _request.default)({
    url: 'system/users/updateAvatarApi',
    method: 'put',
    data: data
  });
}

function enabled(data) {
  return (0, _request.default)({
    url: 'system/users/updateEnabled',
    method: 'put',
    data: data
  });
}

function exportExcel(params) {
  return (0, _request.default)({
    url: 'system/users/exportUser',
    method: 'post',
    responseType: 'blob',
    params: params
  });
} // 获取绑定状态


function isbind(userId) {
  return (0, _request.default)({
    url: 'system/users/' + userId + '/isbind',
    method: 'get'
  });
} // 解绑


function unbind(userId) {
  return (0, _request.default)({
    url: 'system/users/' + userId + '/unbind',
    method: 'get'
  });
} // 获取微信绑定二维码


function getewm(userId) {
  return (0, _request.default)({
    url: 'system/users/' + userId + '/ewm',
    method: 'get'
  });
}

function deldata(userId) {
  return (0, _request.default)({
    url: 'system/users/' + userId + '/truncate',
    method: 'delete'
  });
}