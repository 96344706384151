var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "toggle-click": _vm.toggleSideBar,
          "is-active": _vm.sidebar.opened
        }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "全屏",
                      effect: "dark",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("screenfull", {
                      staticClass: "screenfull right-menu-item"
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.user.headImgUrl }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: {
                        click: function($event) {
                          _vm.show = true
                        }
                      }
                    },
                    [_c("el-dropdown-item", [_vm._v(" 布局设置 ")])],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/center" } },
                    [_c("el-dropdown-item", [_vm._v(" 个人中心 ")])],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.open }
                    },
                    [
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _vm._v(" 退出登录 ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }