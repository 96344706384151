"use strict";

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: 'App',
  data: function data() {
    return {
      path: '/'
    };
  },
  watch: {
    $route: function $route(to, from) {
      if (to.path.indexOf('redirect') !== -1) {
        this.$router.replace(to.path.replace(/.+?redirect/g, '/redirect'));
      }
    }
  }
};
exports.default = _default;