// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/bkg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login[data-v-37dfd6fc] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n.title[data-v-37dfd6fc] {\n  margin: 0px auto 30px auto;\n  text-align: center;\n  color: #707070;\n}\n.login-form[data-v-37dfd6fc] {\n  border-radius: 6px;\n  background: #ffffff;\n  width: 380px;\n  padding: 25px 25px 25px 25px;\n}\n.login-form .el-input[data-v-37dfd6fc] {\n  height: 38px;\n}\n.login-form .el-input input[data-v-37dfd6fc] {\n  height: 38px;\n}\n.login-tip[data-v-37dfd6fc] {\n  font-size: 13px;\n  text-align: center;\n  color: #bfbfbf;\n}\n.login-code[data-v-37dfd6fc] {\n  display: block;\n  float: right;\n  width: 33%;\n  height: 38px;\n}\n.login-code img[data-v-37dfd6fc] {\n  cursor: pointer;\n  vertical-align: middle;\n}\n[data-v-37dfd6fc] .el-form-item__content {\n  line-height: 60px;\n}\n[data-v-37dfd6fc] .el-form-item__error {\n  color: #ff4949;\n  font-size: 12px;\n  line-height: 1;\n  padding-top: 0px;\n  position: absolute;\n  top: 55px;\n  left: 0;\n}", ""]);
// Exports
module.exports = exports;
