"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var app = {
  state: {
    sidebar: {
      opened: !+_jsCookie.default.get('sidebarStatus'),
      withoutAnimation: false
    },
    device: 'desktop'
  },
  mutations: {
    TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
      if (state.sidebar.opened) {
        _jsCookie.default.set('sidebarStatus', 1);
      } else {
        _jsCookie.default.set('sidebarStatus', 0);
      }

      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
    },
    CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
      _jsCookie.default.set('sidebarStatus', 1);

      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
      state.device = device;
    }
  },
  actions: {
    ToggleSideBar: function ToggleSideBar(_ref) {
      var commit = _ref.commit;
      commit('TOGGLE_SIDEBAR');
    },
    closeSideBar: function closeSideBar(_ref2, _ref3) {
      var commit = _ref2.commit;
      var withoutAnimation = _ref3.withoutAnimation;
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    toggleDevice: function toggleDevice(_ref4, device) {
      var commit = _ref4.commit;
      commit('TOGGLE_DEVICE', device);
    }
  }
};
var _default = app;
exports.default = _default;