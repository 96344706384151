"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    Screenfull: _Screenfull.default
  },
  data: function data() {
    return {
      dialogVisible: false
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'user', 'device'])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showRightPanel;
      },
      set: function set(val) {
        this.$store.dispatch('changeSetting', {
          key: 'showRightPanel',
          value: val
        });
      }
    }
  }),
  methods: {
    open: function open() {
      var _this = this;

      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.logout();
      });
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('ToggleSideBar');
    },
    logout: function logout() {
      this.dialogVisible = false;
      this.$store.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    }
  }
};
exports.default = _default;