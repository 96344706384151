"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

var _doshboard = require("@/api/longda/doshboard");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      daycount: 0,
      moncount: 0,
      count: 0,
      mems: 0
    };
  },
  created: function created() {
    var _this = this;

    (0, _doshboard.panelgroup)().then(function (res) {
      var data = res.data;
      console.log(data);
      _this.daycount = data.daycount;
      _this.moncount = data.moncount;
      console.log(_this.daycount);
      console.log(_this.moncount);
      _this.count = data.count;
      _this.mems = data.mems;
    });
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    }
  }
};
exports.default = _default;