"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _Layout = _interopRequireDefault(require("../views/layout/Layout"));

_vue.default.use(_vueRouter.default);
/* Layout */


/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/401'));
    });
  },
  hidden: true
}, {
  path: '/redirect',
  component: _Layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/',
  component: _Layout.default,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: '首页',
    meta: {
      title: '首页',
      icon: 'index',
      noCache: true,
      affix: true
    }
  }]
}, {
  path: '/user',
  component: _Layout.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/user/center'));
      });
    },
    name: '个人中心',
    meta: {
      title: '个人中心',
      icon: 'user'
    }
  }]
} // {
//   path: '/maniacEquipmentConnect',
//   component: Layout,
//   hidden: true,
//   redirect: 'noredirect',
//   children: [
//     {
//       path: 'center',
//       component: () => import('@/views/system/maniacEquipmentConnect/index'),
//       name: '开启记录',
//       meta: { title: '开启记录', icon: 'user' }
//     }
//   ]
// }
// { path: '*', redirect: '/404', hidden: true }
];
exports.constantRouterMap = constantRouterMap;

var _default = new _vueRouter.default({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});

exports.default = _default;