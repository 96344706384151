"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _logo = _interopRequireDefault(require("@/assets/logo/logo1.png"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Logo',
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      logoImg: _logo.default
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebarLogo: function sidebarLogo(state) {
      return state.settings.sidebarLogo;
    }
  }))
};
exports.default = _default;