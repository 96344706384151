"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _routers = _interopRequireDefault(require("@/router/routers"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("../store"));

var _auth = require("@/utils/auth");

var _config = _interopRequireDefault(require("@/config"));

// 创建axios实例
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: _config.default.timeout // 请求超时时间

}); // request拦截器


service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  } // config.headers['Content-Type'] = 'application/json'
  // console.info(config)


  return config;
}, function (error) {
  // Do something with request error
  // console.log(error) // for debug
  Promise.reject(error);
}); // response 拦截器

service.interceptors.response.use(function (response) {
  var code = response.status;

  if (code < 200 || code > 300) {
    _elementUi.Notification.error({
      title: response.message
    });

    return Promise.reject('error');
  } else {
    return response.data;
  }
}, function (error) {
  var code = 0;

  try {
    code = error.response.status;
  } catch (e) {
    console.log(error.toString());

    if (error.toString().indexOf('Error: timeout') !== -1) {
      _elementUi.Notification.error({
        title: '网络请求超时',
        duration: 2500
      });

      return Promise.reject(error);
    }

    if (error.toString().indexOf('Error: Network Error') !== -1) {
      _elementUi.Notification.error({
        title: '网络请求错误',
        duration: 2500
      });

      return Promise.reject(error);
    }
  }

  if (code === 400) {
    _elementUi.Notification.error({
      title: error.response.data.msg,
      duration: 2500
    });

    return Promise.reject(error);
  }

  if (code === 401) {
    console.info(error.response);

    if (error.response.data.error === 'invalid_token' || error.response.data.msg.indexOf('Invalid access token') !== -1) {
      _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('LogOut').then(function () {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    } else if (error.response.data.msg === 'Access is denied') {
      _elementUi.Notification.error({
        title: '当前应用无权限访问该API',
        duration: 2500
      });

      return Promise.reject(error);
    } // return Promise.reject(error)

  } else if (code === 402) {
    _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
  } else if (code === 403) {
    _routers.default.push({
      path: '/401'
    });
  } else {
    var errorMsg = error.response.data.msg;
    console.log(error);

    if (errorMsg !== undefined) {
      _elementUi.Notification.error({
        title: errorMsg,
        duration: 2500
      });
    }
  }

  return Promise.reject(error);
});
var _default = service;
exports.default = _default;