"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.panelgroup = panelgroup;

var _request = _interopRequireDefault(require("@/utils/request"));

function panelgroup() {
  return (0, _request.default)({
    url: 'manage/dashboard/count',
    method: 'get'
  });
}