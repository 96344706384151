"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMenusTree = getMenusTree;
exports.buildMenus = buildMenus;
exports.add = add;
exports.del = del;
exports.edit = edit;

var _request = _interopRequireDefault(require("@/utils/request"));

// 获取所有的菜单树
function getMenusTree() {
  return (0, _request.default)({
    url: 'system/menus/tree',
    method: 'get'
  });
}

function buildMenus() {
  return (0, _request.default)({
    url: 'system/menus/build',
    method: 'get'
  });
}

function add(data) {
  return (0, _request.default)({
    url: 'system/menus',
    method: 'post',
    data: data
  });
}

function del(id) {
  return (0, _request.default)({
    url: 'system/menus/' + id,
    method: 'delete'
  });
}

function edit(data) {
  return (0, _request.default)({
    url: 'system/menus',
    method: 'put',
    data: data
  });
}