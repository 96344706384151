var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialog,
            title: _vm.title,
            "append-to-body": "",
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialog = $event
            },
            close: _vm.cancel
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
                "label-width": "88px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "旧密码", prop: "oldPass" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "370px" },
                    attrs: { type: "password", "auto-complete": "on" },
                    model: {
                      value: _vm.form.oldPass,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "oldPass", $$v)
                      },
                      expression: "form.oldPass"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "newPass" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "370px" },
                    attrs: { type: "password", "auto-complete": "on" },
                    model: {
                      value: _vm.form.newPass,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newPass", $$v)
                      },
                      expression: "form.newPass"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "confirmPass" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "370px" },
                    attrs: { type: "password", "auto-complete": "on" },
                    model: {
                      value: _vm.form.confirmPass,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "confirmPass", $$v)
                      },
                      expression: "form.confirmPass"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.doSubmit }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }