var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sidebarLogo
    ? _c(
        "el-menu-item",
        {
          class: { "submenu-title-noDropdown": _vm.isCollapse },
          staticStyle: {
            "pointer-events": "none",
            height: "56px !important",
            "line-height": "56px"
          },
          attrs: { index: "0" }
        },
        [
          _vm.isCollapse
            ? _c("svg-icon", { attrs: { "icon-class": "flag" } })
            : _vm._e(),
          _c("div", { staticClass: "logo-con" }, [
            _c("img", { attrs: { src: _vm.logoImg } })
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }