"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _initData = _interopRequireDefault(require("@/mixins/initData"));

var _index = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_initData.default],
  data: function data() {
    return {
      flags: [{
        value: '0',
        label: '成功'
      }, {
        value: '1',
        label: '失败'
      }]
    };
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      _this.init();
    });
  },
  methods: {
    parseTime: _index.parseTime,
    beforeInit: function beforeInit() {
      this.url = 'system/users/logs';
      this.params = {
        page: this.page,
        size: this.size
      };
      return true;
    }
  }
};
exports.default = _default;