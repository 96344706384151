"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("D:\\work\\projectme\\longda\\web\\node_modules\\core-js\\modules\\es.array.iterator.js");

require("D:\\work\\projectme\\longda\\web\\node_modules\\core-js\\modules\\es.promise.js");

require("D:\\work\\projectme\\longda\\web\\node_modules\\core-js\\modules\\es.object.assign.js");

require("D:\\work\\projectme\\longda\\web\\node_modules\\core-js\\modules\\es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

var _mavonEditor = _interopRequireDefault(require("mavon-editor"));

require("mavon-editor/dist/css/index.css");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _routers = _interopRequireDefault(require("./router/routers"));

var _permission = _interopRequireDefault(require("./components/permission"));

var _store = _interopRequireDefault(require("./store"));

require("./icons");

require("./router/index");

var _print = _interopRequireDefault(require("./utils/print.js"));

// A modern alternative to CSS resets
// lang i18n
// global css
// icon
// permission control
_vue.default.use(_mavonEditor.default);

_vue.default.use(_permission.default);

_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});

_vue.default.use(_print.default);

_vue.default.config.productionTip = false; // require('babel-polyfill')

new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});