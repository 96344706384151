"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));

var _permission = _interopRequireDefault(require("@/utils/permission"));

var _initData = _interopRequireDefault(require("@/mixins/initData"));

var _initDict = _interopRequireDefault(require("@/mixins/initDict"));

var _index = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
};
var _default = {
  name: 'DashboardAdmin',
  components: {
    PanelGroup: _PanelGroup.default // BarChart
    // TodoList,
    // BoxCard

  },
  mixins: [_initData.default, _initDict.default],
  data: function data() {
    return {
      lineChartData: lineChartData.newVisitis
    };
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      _this.init();
    });
  },
  methods: {
    parseTime: _index.parseTime,
    checkPermission: _permission.default,
    beforeInit: function beforeInit() {
      this.url = '/manage/dashboard';
      this.params = {};
      return true;
    },
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    }
  }
};
exports.default = _default;