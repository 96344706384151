"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _path = _interopRequireDefault(require("path"));

var _utils = require("@/utils");

var _Item = _interopRequireDefault(require("./Item"));

var _Link = _interopRequireDefault(require("./Link"));

var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      onlyOneChild: null
    };
  },
  methods: {
    hasOneShowingChild: function hasOneShowingChild(children, parent) {
      var _this = this;

      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      }); // When there is only one child router, the child router is displayed by default

      if (showingChildren.length === 1) {
        return true;
      } // Show parent if there are no child router to display


      if (showingChildren.length === 0) {
        this.onlyOneChild = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: '',
          noShowingChildren: true
        });
        return true;
      }

      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }

      return _path.default.resolve(this.basePath, routePath);
    },
    isExternalLink: function isExternalLink(routePath) {
      return (0, _utils.isExternal)(routePath);
    }
  }
};
exports.default = _default;