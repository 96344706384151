"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

var _user = require("@/api/system/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;

    var validatePass = function validatePass(rule, value, callback) {
      if (value) {
        (0, _user.validPass)(value).then(function (res) {
          if (res.code === 200) {
            callback();
          } else {
            callback(new Error('旧密码错误，请检查'));
          }
        });
      } else {
        callback(new Error('请输入旧密码'));
      }
    };

    var confirmPass = function confirmPass(rule, value, callback) {
      if (_this.form.newPass !== value) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      dialog: false,
      title: '修改密码',
      form: {
        oldPass: '',
        newPass: '',
        confirmPass: ''
      },
      rules: {
        oldPass: [{
          required: true,
          validator: validatePass,
          trigger: 'blur'
        }],
        newPass: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 20,
          message: '长度在 6 到 20 个字符',
          trigger: 'blur'
        }],
        confirmPass: [{
          required: true,
          validator: confirmPass,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    cancel: function cancel() {
      this.resetForm();
    },
    doSubmit: function doSubmit() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          (0, _user.updatePass)({
            newPassword: _this2.form.newPass,
            oldPassword: _this2.form.oldPass
          }).then(function (res) {
            _this2.resetForm();

            _this2.$notify({
              title: '密码修改成功，请重新登录',
              type: 'success',
              duration: 1500
            });

            setTimeout(function () {
              _store.default.dispatch('LogOut').then(function () {
                location.reload(); // 为了重新实例化vue-router对象 避免bug
              });
            }, 1500);
          }).catch(function (err) {
            _this2.loading = false;
            console.log(err.response.data.message);
          });
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm() {
      this.dialog = false;
      this.$refs['form'].resetFields();
      this.form = {
        oldPass: '',
        newPass: '',
        confirmPass: ''
      };
    }
  }
};
exports.default = _default;