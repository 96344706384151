"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _index = require("@/utils/index");

var _updatePass = _interopRequireDefault(require("./center/updatePass"));

var _log = _interopRequireDefault(require("./center/log"));

var _auth = require("@/utils/auth");

var _store = _interopRequireDefault(require("@/store"));

var _user = require("@/api/system/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Center',
  components: {
    updatePass: _updatePass.default,
    log: _log.default
  },
  data: function data() {
    return {
      ico: 'el-icon-refresh',
      headers: {
        'Authorization': 'Bearer ' + (0, _auth.getToken)()
      },
      bucket: {
        bucket: 'avatar'
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user', 'updateAvatarApi'])),
  created: function created() {
    _store.default.dispatch('GetInfo').then(function () {});
  },
  methods: {
    parseTime: _index.parseTime,
    formatEmail: function formatEmail(mail) {
      return (0, _index.regEmail)(mail);
    },
    handleSuccess: function handleSuccess(response, file, fileList) {
      var _this = this;

      var fileInfo = response.data.fileInfo; // fileInfo.url = fileInfo.url.indexOf('http') !== -1 ? fileInfo.url : process.env.VUE_APP_BASE_API + fileInfo.url

      (0, _user.updateAvtar)({
        headImgUrl: fileInfo.url
      }).then(function (res) {
        if (res.code === 200) {
          _this.$notify({
            title: '头像修改成功',
            type: 'success',
            duration: 2500
          });

          _store.default.dispatch('GetInfo').then(function () {});
        }
      });
    },
    // 监听上传失败
    handleError: function handleError(e, file, fileList) {
      var msg = JSON.parse(e.message);
      this.$notify({
        title: msg.msg,
        type: 'error',
        duration: 2500
      });
    },
    refresh: function refresh() {
      var _this2 = this;

      this.ico = 'el-icon-loading';
      this.$refs.log.init();
      setTimeout(function () {
        _this2.ico = 'el-icon-refresh';
      }, 300);
    }
  }
};
exports.default = _default;