"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.join");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _config = _interopRequireDefault(require("@/config"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    return {
      codeUrl: '',
      loginForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: '',
        uuid: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '用户名不能为空'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: '密码不能为空'
        }],
        code: [{
          required: true,
          trigger: 'change',
          message: '验证码不能为空'
        }]
      },
      loading: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCookie();
    this.getCode();
  },
  methods: {
    uuid: function uuid() {
      var s = [];
      var hexDigits = '0123456789abcdef';

      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }

      s[14] = '4';
      s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = '-';
      return s.join('');
    },
    getCode: function getCode() {
      // getCodeImg().then(res => {
      //   this.codeUrl = 'data:image/gif;base64,' + res.img
      //   this.loginForm.uuid = res.uuid
      // })
      this.loginForm.uuid = this.uuid(); // this.codeUrl = 'http://localhost:9200/api-auth/validata/code/' + this.loginForm.uuid

      this.codeUrl = process.env.VUE_APP_BASE_API + '/oauth/validata/code/' + this.loginForm.uuid;
    },
    getCookie: function getCookie() {
      var username = _jsCookie.default.get('username');

      var password = _jsCookie.default.get('password');

      var rememberMe = _jsCookie.default.get('rememberMe');

      password = password === undefined ? this.loginForm.password : password;
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
        code: ''
      };
    },
    handleLogin: function handleLogin() {
      var _this = this;

      this.$refs.loginForm.validate(function (valid) {
        var user = _this.loginForm;

        if (valid) {
          _this.loading = true;

          if (user.rememberMe) {
            _jsCookie.default.set('username', user.username, {
              expires: _config.default.passCookieExpires
            });

            _jsCookie.default.set('password', user.password, {
              expires: _config.default.passCookieExpires
            });

            _jsCookie.default.set('rememberMe', user.rememberMe, {
              expires: _config.default.passCookieExpires
            });
          } else {
            _jsCookie.default.remove('username');

            _jsCookie.default.remove('password');

            _jsCookie.default.remove('rememberMe');
          }

          _this.$store.dispatch('Login', user).then(function () {
            _this.loading = false;

            _this.$router.push({
              path: _this.redirect || '/'
            });
          }).catch(function () {
            _this.loading = false;

            _this.getCode();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;