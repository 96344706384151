"use strict";

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.regexp.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.regexp.to-string");

require("core-js/modules/es.string.includes");

require("core-js/modules/es.string.match");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTime = parseTime;
exports.formatTime = formatTime;
exports.debounce = debounce;
exports.isExternal = isExternal;
exports.regEmail = regEmail;
exports.regMobile = regMobile;
exports.hasClass = hasClass;
exports.addClass = addClass;
exports.removeClass = removeClass;

/**
 * Created by jiachenpan on 16/11/18.
 */
function parseTime(time) {
  if (time) {
    if (typeof time === 'string') {
      if (typeof time === 'string' && (time.indexOf('T') > -1 || time.includes('T'))) {
        time = time.replace('T', ' ').replace(/\-/g, '/'); // 注意：指定一个具体的时间转换时间戳，需要yyyy/mm/dd hh:ii:ss格式，yyyy-mm-dd在IE和Safari下是有问题的。
      }

      if (time.indexOf('.000+') > -1 || time.includes('.000')) {
        var index = time.indexOf('.');
        time = time.substring(0, index); // time = time.replace('.000+0000', '') // 注意：指定一个具体的时间转换时间戳，需要yyyy/mm/dd hh:ii:ss .000+0000格式，yyyy-mm-dd在IE和Safari下是有问题的。
      }
    }

    var date = new Date(time);
    var year = date.getFullYear();
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */

    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds(); // 拼接

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  } else {
    return '';
  }
}

function formatTime(time, option) {
  time = +time * 1000;
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;

  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp; // 上次被包装函数被调用时间间隔last小于设定时间间隔wait

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null; // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用

      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout; // 如果延时不存在，重新设定延时

    if (!timeout) timeout = setTimeout(later, wait);

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
} // 替换邮箱字符


function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    var str = email.split('@');
    var _s = '';

    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*';
      }
    }

    var new_email = str[0].substr(0, 3) + _s + '@' + str[1];
  }

  return new_email;
} // 替换手机字符


function regMobile(mobile) {
  if (mobile.length > 7) {
    var new_mobile = mobile.substr(0, 3) + '****' + mobile.substr(7);
  }

  return new_mobile;
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */


function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */


function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */


function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}