var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    { attrs: { "wrap-class": "scrollbar-wrapper" } },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "show-timeout": 200,
            "default-active": _vm.$route.path,
            collapse: _vm.isCollapse,
            "unique-opened": _vm.$store.state.settings.uniqueOpened,
            mode: "vertical",
            "background-color": "#304156",
            "text-color": "#bfcbd9",
            "active-text-color": "#409EFF"
          }
        },
        [
          _c("Logo", { attrs: { "is-collapse": _vm.isCollapse } }),
          _vm._l(_vm.permission_routers, function(route) {
            return _c("sidebar-item", {
              key: route.path,
              attrs: { item: route, "base-path": route.path }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }