var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group"),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "10px 10px 0",
            "margin-bottom": "16px",
            "min-height": "500px"
          }
        },
        [
          _c("div", { staticClass: "card-panel-text" }, [_vm._v(" 预约列表 ")]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { data: _vm.data, size: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "70px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNo", label: "订单编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orderNo))])]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "username", label: "预约会员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.username))])]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "electricTime", label: "预约时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parseTime(scope.row.electricTime * 1000))
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "electricNum", label: "预约人数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.electricNum))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "addressName", label: "地址名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.addressName))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "详细地址" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.address))])]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "下单时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.parseTime(scope.row.createTime * 1000))
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }