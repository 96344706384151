"use strict";

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.object.to-string");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.setUserInfo = void 0;

var _login = require("@/api/login");

var _auth = require("@/utils/auth");

var _elementUi = require("element-ui");

var user = {
  state: {
    token: (0, _auth.getToken)(),
    user: {},
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var username = userInfo.username;
      var password = userInfo.password;
      var code = userInfo.code;
      var uuid = userInfo.uuid;
      var rememberMe = userInfo.rememberMe; // const grant_type = 'password'
      // const scope = 'app'
      // const client_id = 'webApp'
      // const client_secret = 'webApp'

      return new Promise(function (resolve, reject) {
        //  login(username, password, code, uuid).then(res => {
        // logintoken(username, password, code, uuid, grant_type, scope, client_id, client_secret).then(res => {
        // logintokenfast(username, password, code, uuid, grant_type, scope, client_id, client_secret).then(res => {
        (0, _login.login)(username, password, code, uuid).then(function (res) {
          var resolve1 = resolve;
          console.info(res);

          if (res.code) {
            var msg = res.msg.indexOf('UserDetailsService returned null') !== -1 ? '账号错误' : res.msg;

            _elementUi.Notification.error({
              title: msg,
              duration: 2500
            });

            reject(new Error('xx'));
          } else {
            (0, _auth.setToken)(res.access_token, rememberMe);
            console.log((0, _auth.getToken)());
            commit('SET_TOKEN', res.access_token);
            (0, _login.getInfo)().then(function (res) {
              console.info(res);
              setUserInfo(res.user, commit);
              commit('SET_LOAD_MENUS', true);
              resolve1();
            });
          } // // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js

        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)().then(function (res) {
          console.log(res);
          setUserInfo(res.user, commit);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        (0, _auth.removeToken)();
        resolve();
      });
    },
    updateLoadMenus: function updateLoadMenus(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_LOAD_MENUS', false);
      });
    }
  }
};

var setUserInfo = function setUserInfo(res, commit) {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.permissions.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT']);
  } else {
    console.info(res.permissions);
    console.info(res);

    if (res.headImgUrl) {
      res.headImgUrl = res.headImgUrl.indexOf('http') !== -1 ? res.headImgUrl : process.env.VUE_APP_BASE_API + res.headImgUrl;
    }

    var arr = [];
    res.permissions.forEach(function (val) {
      arr.push(val);
    });
    res.sysRoles.forEach(function (val) {
      arr.push('ROLE_' + val.code);
    });
    console.info(arr);
    commit('SET_ROLES', arr);
  }

  commit('SET_USER', res);
};

exports.setUserInfo = setUserInfo;
var _default = user;
exports.default = _default;