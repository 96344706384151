var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden && _vm.item.children
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _c(
                  "app-link",
                  { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { "submenu-title-noDropdown": !_vm.isNest },
                        attrs: { index: _vm.resolvePath(_vm.onlyOneChild.path) }
                      },
                      [
                        _vm.onlyOneChild.meta
                          ? _c("item", {
                              attrs: {
                                icon:
                                  _vm.onlyOneChild.meta.icon ||
                                  _vm.item.meta.icon,
                                title: _vm.onlyOneChild.meta.title
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _c(
                "el-submenu",
                {
                  ref: "submenu",
                  attrs: { index: _vm.resolvePath(_vm.item.path) }
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.item.meta
                        ? _c("item", {
                            attrs: {
                              icon: _vm.item.meta.icon,
                              title: _vm.item.meta.title
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._l(_vm.item.children, function(child) {
                    return [
                      !child.hidden
                        ? [
                            child.children && child.children.length > 0
                              ? _c("sidebar-item", {
                                  key: child.path,
                                  staticClass: "nest-menu",
                                  attrs: {
                                    "is-nest": true,
                                    item: child,
                                    "base-path": _vm.resolvePath(child.path)
                                  }
                                })
                              : _c(
                                  "app-link",
                                  {
                                    key: child.name,
                                    attrs: { to: _vm.resolvePath(child.path) }
                                  },
                                  [
                                    _c(
                                      "el-menu-item",
                                      {
                                        attrs: {
                                          index: _vm.resolvePath(child.path)
                                        }
                                      },
                                      [
                                        child.meta
                                          ? _c("item", {
                                              attrs: {
                                                icon: child.meta.icon,
                                                title: child.meta.title
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ]
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }