"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var baseUrl = process.env.VUE_APP_BASE_API;
var api = {
  state: {
    // 图片上传
    imagesUploadApi: baseUrl + '/file/files-anon',
    // 修改头像
    updateAvatarApi: baseUrl + '/file/files-anon',
    // Sql 监控
    sqlApi: baseUrl + '/druid',
    // swagger
    swaggerApi: baseUrl + '/swagger-ui.html',
    // 设备信息上传
    uploadEqInfo: baseUrl + '/devops/equipment/uploadEqInfo'
  }
};
var _default = api;
exports.default = _default;