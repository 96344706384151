"use strict";

var _interopRequireDefault = require("D:/work/projectme/longda/web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map");

require("core-js/modules/es.object.to-string");

require("core-js/modules/web.dom-collections.iterator");

var _vue = _interopRequireDefault(require("vue"));

var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));

// svg组件
// register globally
_vue.default.component('svg-icon', _SvgIcon.default);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

var req = require.context('./svg', false, /\.svg$/);

requireAll(req);